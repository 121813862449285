import {connect} from 'react-redux';

import DeleteBill from '../Component/DeleteBill';
import {deleteUserBill} from '../../core/actions/action';


const mapStateToProps = state => {

    return{
        viewUserBill:state.UserActiveBill.viewUserBill,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        deleteUserBill :(billNo, isDelete) => {
            return deleteUserBill(billNo, isDelete);
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteBill);
