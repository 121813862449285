import {connect} from 'react-redux';

import UploadBill from '../Component/UploadBill'
import {getUserActiveBill, getBrandForBillUpload, uploadUserNewBill} from '../../core/actions/action';
import {viewUserActiveBillAction, brandForBillUploadAction} from '../../core/actions';


const mapStateToProps = state => {

    return{
        userActiveBillList:state.UserActiveBill.userActiveBillList,
        uploadBillBrandList:state.UserActiveBill.uploadBillBrandList,
        uploadUserBill:state.UserActiveBill.uploadUserBill,

    }
}

const mapDispatchToProps = dispatch => {

    return {

        getUserActiveBill :(page, limit) => {
            return getUserActiveBill(page, limit)
                .then((res)=>{
                    const sources = res.data;

                    if(sources.status===true){
                        dispatch(viewUserActiveBillAction(sources.data.data));
                    }else{
                        dispatch(viewUserActiveBillAction(null));
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    console.log(sources);
                })
        },


        getBrandForBillUpload :() => {
            return getBrandForBillUpload()
                .then((res)=>{
                    const sources = res.data.data;
                    if(res.data.status===true){
                        dispatch(brandForBillUploadAction(sources));
                    }else{
                        dispatch(brandForBillUploadAction(null));
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    console.log(sources);
                })
        },

        uploadUserNewBill :(formData) => {
            return uploadUserNewBill(formData)
            /*.then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    dispatch(uploadBillAction(sources));
                }else{
                    dispatch(uploadBillAction(null));
                }
            }).catch((error)=>{
                const sources = error.response;
            })*/
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadBill);
