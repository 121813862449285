export const ENV = '.com';
const config = {

    APP_URL: 'https://best-shopping-websites.globalgarner.com/',
    API_URL: 'https://best-shopping-websites-api.globalgarner.com/',
    ACCOUNT_API_URL:'https://accounts.globalgarner.com/',
    ADMIN_API_URL:'https://admin.globalgarner.com/api/',
    STORE_FRONT_URL: 'https://store.globalgarner.com/',
    DEFAULT_IMAGE_URL: 'https://s3.ap-south-1.amazonaws.com/gg-statics/gg-statics-staging/Default.png',
    Extension:".com",
    IS_MAINTENANCE_MODE: false
};

export const packageConfigure={
}

export default config;
