import {ssoInstance} from 'gg-react-utilities';
import Config from '../Config';

export const getCategoryList = () => {

    return ssoInstance.get(Config.API_URL + 'user/v3/categories')
}

export const getBrandsList = (params) => {

    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');

    return ssoInstance.get(Config.API_URL + 'user/v3/affiliates?'+queryString)
}


export const getBrandsDetails = (slug) => {

    return ssoInstance.get(Config.API_URL + 'user/v3/affiliates/' + slug)
}


export const getViewBrandLink = (slug) => {

    return ssoInstance.post(Config.API_URL + 'user/v3/user/visit?platform=web&affiliate_slug=' + slug)
}

export const getUserActiveBill = (page) => {

    return ssoInstance.get(Config.API_URL + 'user/v3/user/user_bills?&page='+page)
}

export const getUserBillView = (billNo) => {

    return ssoInstance.get(Config.API_URL + 'user/v3/user/bill/'+billNo)
}

export const deleteUserBill = (billNo, isDelete) => {

    return ssoInstance.post(Config.API_URL + 'user/v3/user/bills?bill_number='+billNo + '&is_delete=' + isDelete)
}


export const getUserTrashBill = (page) => {

    return ssoInstance.get(Config.API_URL + 'user/v3/user/trash_bill?&page='+page)
}


export const postUserFavouriteBrand = (affiliate) => {

    return ssoInstance.post(Config.API_URL + 'user/v3/favourite?affiliate_id='+affiliate )
}


export const getBrandForBillUpload= () => {

    return ssoInstance.get(Config.API_URL + 'user/v3/affiliates/bill/affiliate')
}

export const uploadUserNewBill= (formData) => {

    return ssoInstance.post(Config.API_URL + 'user/v3/user/bill/upload', formData)
}


export const getBrandsSlider = () => {

    return ssoInstance.get(Config.API_URL + 'user/v3/sliders')
}