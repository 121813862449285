import React from 'react';
//import SearchBar from './SearchBar';
import BrandsList from './BrandsList';
import LoadingBrandList from './LoadingBrandList';
import {Button } from "@blueprintjs/core";
import OrderEmptyImg from "../../core/images/empty-state/No brand found.svg";


export default class ShowBrands extends React.Component {
    constructor(props){
        super(props);

        this.state={
            currentPageNo:1,
            pageValue:null,
            pageNo: props.pageNo,
            render: false,
            loading: props.loading,
            name: "",
            tempName: "",
            brandList:[],
            affiliateId:props.affiliateIdData,
            isLoginStatus: false,
        };
    }

    componentDidMount(){
        this.setState( {
            loading: this.props.loading,
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading,
            brandList:nextProps.brandList
        })
    }

    getBrands(){
        this.props.NextPage()

        this.setState({
            isLoginStatus:true
        });
        this.stopLogin();
    }

    stopLogin = () => {
        setTimeout(() => {
            this.setState({
                isLoginStatus: false,
            });
        }, 1000);
    }

    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {
                        this.state.loading ?
                           <LoadingBrandList/>
                           :

                           this.state.brandList.length > 0 ?
                                <BrandsList
                                    pageNo={this.state.currentPageNo}
                                    affiliates={this.state.brandList}
                                    affiliateIdData={this.state.affiliateId}
                                    onFavourit={this.props.onFavourit}
                                />
                                :
                                this.props.showEmptyStats?
                                    <div className="col-12 text-center">
                                        <img src={OrderEmptyImg} alt=""/>
                                        <p className="mt-3">Oops, We didn’t found any brand.</p>
                                    </div>
                                    :
                                    ""
                    }
                </div>
                {
                    this.state.brandList.length >= 15 ?
                        <div className="text-center my-4">
                            {
                                this.state.loading ?
                                    <div className="col-12 img-full mb-2">
                                        <div className="bp3-skeleton col-12"
                                             style={{width: "90%", height: "40px", margin: "0 auto"}}>&nbsp;</div>
                                    </div>
                                    :
                                    this.props.stopLoadMore ?
                                        ""
                                        :
                                        <center><Button intent="primary" className="btnLoadMoreBSW" text="Load More" onClick={() => this.getBrands()} loading={this.state.isLoginStatus}/></center>
                            }
                        </div>
                        :
                        ""
                }
            </React.Fragment>
        );
    }
}