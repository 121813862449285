let initialState = {
    brandDetailsSlug:[],
    brandLink:null,
};

const brandDetails = (state = initialState, action) => {

    switch (action.type) {

        case 'BRAND_DETAILS':
            return {...state, brandDetailsSlug: action.sources}
        case 'BRAND_LINK':
            return {...state, brandLink: action.sources}
        default:
            return state
    }
}

export default brandDetails;
