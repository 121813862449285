import React from 'react';
import renderHTML from 'react-render-html';
import {Button, Classes, Dialog, Intent, Position, Toaster} from "@blueprintjs/core";
import TopBar from "../../core/components/TopBar";
import GgLogo from "../../core/images/brands/Group 5.svg";
import setp1 from "../../core/images/brands/01.svg";
import setp2 from "../../core/images/brands/02.svg";
import setp3 from "../../core/images/brands/03.svg";
import {Link} from "react-router-dom";
import {Auth} from 'gg-react-utilities';
import _ from 'lodash';

export default class BrandsDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            brandData: [],
            brandLink: [],
            linkStatus: false,
            isOpen: false,
            buttonStatus: true
        };
    }

    componentDidMount() {
        this.props.getBrandsDetails(this.props.match.params.slug);
        this.setState({brandLink: this.props.brandLink})
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    }


    componentWillReceiveProps(nextProps) {
        this.setState({brandData: nextProps.brandDetailsSlug});
        /*if(nextProps.brandLink){
            this.setState({ brandLink:nextProps.brandLink, linkStatus:true})
        }*/
    }

    handleOpen(BrandLinkData) {
        this.setState({buttonStatus: false});
        this.props.getViewBrandLink(this.props.match.params.slug)
            .then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    this.setState({isOpen: true});
                    setTimeout(() => {
                        this.handleClose();
                        let webLink = BrandLinkData;
                        window.open(webLink, '_blank');
                        window.focus();
                        this.setState({brandLink: []});
                    }, 3000);
                } else {
                    this.setState({buttonStatus: true});
                    Toaster.create({position: Position.TOP}).show({
                        message: "Something went wrong!",
                        intent: Intent.DANGER
                    });
                }
            }).catch((error) => {
            if (Auth.isLoggedIn() === true) {
                const sources = error.response;
                Toaster.create({position: Position.TOP}).show({message: sources.data.message, intent: Intent.DANGER});
                setTimeout(() => {
                    this.setState({
                        buttonStatus: true,
                    });
                }, 1000);
            }
        })
        this.stopLogin();
    }

    stopLogin = () => {
        if (Auth.isLoggedIn() === false) {
            setTimeout(() => {
                this.setState({
                    buttonStatus: true,
                });
            }, 1000);
        }
    }

    handleClose() {
        this.setState({isOpen: false, buttonStatus: true});
    }

    render() {
        if (this.state.brandData.length === 0) {
            return (
                <React.Fragment>
                    <TopBar title={"Searching..."}/>
                    <div>
                        <div className="col-12 img-full mb-2">
                            <div className="bp3-skeleton" style={{width: "100%", height: "500px"}}>&nbsp;</div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        let splitColorCode = _.split(this.state.brandData.color_code, ',');
        let backgroundColorCode = {backgroundImage: `linear-gradient(135deg, ${splitColorCode[0]} 0%, ${splitColorCode[1]} 100%)`};
        if (this.state.brandData.meta_title !== undefined) {
            let metaData = document.getElementsByTagName("meta");
            metaData['keywords'].content = this.state.brandData.meta_keyword;
            metaData['description'].content = this.state.brandData.meta_desc;

            document.title = this.state.brandData.meta_title;
            // var meta = document.createElement('meta');
            // var meta1 = document.createElement('meta');
            // meta.name = "description";
            // meta.content = this.state.brandData.meta_desc;
            // meta1.name = 'keywords';
            // meta1.content = this.state.brandData.meta_keyword;
            // document.getElementsByTagName('head')[0].appendChild(meta);
            // document.getElementsByTagName('head')[0].appendChild(meta1);
        }
        return (
            <React.Fragment>
                <TopBar title={this.state.brandData.name}/>
                <div className="row bill-border brand-details mb-5">
                    <div className={"brand-name col-12"} style={backgroundColorCode}>
                        <div className="brandsImg">
                            <img alt={this.state.brandData.name} src={this.state.brandData.logo} className="img-fluid"/>
                        </div>
                        <h4 className="img-name">{this.state.brandData.name}</h4>
                    </div>

                    <div className="col-12 brand-desc">
                        <h1 className="bp3-text-xlarge heading mt-3">Just one step away from 100% cashback/reward</h1>
                        <ul>
                            <li>{renderHTML(this.state.brandData.terms_condition.replace(/(?:\r\n|\r|\n)/g, '<br />'))}</li>
                        </ul>
                        {
                            this.state.brandData.message !== "" ?
                                <span><strong>NOTE:- </strong>{this.state.brandData.message}</span>
                                :
                                ""
                        }

                    </div>

                    <div className="col-12 my-5 button-section">
                        {
                            this.state.buttonStatus ?
                                <Button className="bp3-button bp3-large mb-4 btn-accept" intent={Intent.PRIMARY}
                                        text="I accept all the terms and conditions"
                                        onClick={() => this.handleOpen(this.state.brandData.link)}/>
                                :
                                <Button className="bp3-button bp3-large mb-4 btn-accept" intent={Intent.PRIMARY}
                                        text="Please Wait..."/>
                        }
                        <div className="clearfix"></div>
                        <Link to={"/search"}>Cancel</Link>
                    </div>
                </div>

                <Dialog isOpen={this.state.isOpen} className="bp3-dialog-large">
                    <div className={Classes.DIALOG_BODY}>
                        <div className="row">
                            <div className="col-xl-12 col-12">
                                <div className="row brand-progress">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-center">
                                        <img alt="" src={GgLogo} className="img-fluid"/>
                                    </div>
                                    <div
                                        className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 brand-progress text-center">
                                        <img alt="" src={setp1} className="img-fluid arrow-pro"/>
                                        <img alt="" src={setp2} className="img-fluid arrow-pro"/>
                                        <img alt="" src={setp3} className="img-fluid arrow-pro"/>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-center mt-s-4">
                                        <img alt={this.state.brandData.name} src={this.state.brandData.logo}
                                             className="link-img"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 text-center mt-s-4">
                                You found a great deal on <b>Global Garner</b> taking you
                                to <br></br> the <b> {this.state.brandData.name} </b>website.
                            </div>
                        </div>
                    </div>
                    {/*<div className="d-flex mt-3">
                        <div className="col-12">
                            <a onClick={() => this.handleClose()} className="bp3-button bp3-secondary float-right" href={this.state.brandLink.link} target="_blank">Close</a>
                        </div>
                    </div>*/}
                </Dialog>
            </React.Fragment>
        );
    }
}
