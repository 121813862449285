let initialState = {
    userActiveBillList:[],
    viewUserBill:null,
    deleteBill:null,
    uploadBillBrandList:[],
    uploadUserBill:null
};

const useractivebill = (state = initialState, action) => {

    switch (action.type) {

        case 'USER_ACTIVE_BILLS':
            return {...state, userActiveBillList: action.sources}
        case 'USER_BILLS':
            return {...state, viewUserBill: action.sources}
        case 'DELETE_USER_BILLS':
            return {...state, deleteBill: action.sources}
        case 'BRAND_FOR_UPLOAD_BILL':
            return {...state, uploadBillBrandList: action.sources}
        case 'UPLOAD_USER_BILL':
            return {...state, uploadUserBill: action.sources}
        default:
            return state
    }
}

export default useractivebill;
