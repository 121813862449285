import React from 'react';
import AddBill from './AddBill'
import BillHistory from './BillHistory'
import {Intent, Position, Toaster} from "@blueprintjs/core";

export default class UploadBill extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            loading: true,
            page: 1,
            limit: 12,
            userBills: [],
            brandBill: [],
            bill_no: null,
        };
    }

    componentDidMount() {
        this.setState({
            loading: this.props.loading,
            userBills: this.props.userActiveBillList
        }, () => {
            this.props.getUserActiveBill(this.state.page, this.state.limit);
            this.props.getBrandForBillUpload();
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            loading: nextProps.loading,
            userBills: nextProps.userActiveBillList,
            brandBill: nextProps.uploadBillBrandList
        })
    }

    uploadBill = (formData) => {
        this.props.uploadUserNewBill(formData)
            .then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    Toaster.create({position: Position.TOP}).show({message: sources.message, intent: Intent.SUCCESS});
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            }).catch((error) => {
            const sources = error.response;
            if ((sources.data.error.bill_url) && (sources.data.error.bill_url.length > 0)) {
                Toaster.create({position: Position.TOP}).show({
                    message: sources.data.error.bill_url,
                    intent: Intent.DANGER
                });
            }
            if ((sources.data.error.date_of_purchase) && (sources.data.error.date_of_purchase.length > 0)) {
                Toaster.create({position: Position.TOP}).show({
                    message: sources.data.error.date_of_purchase,
                    intent: Intent.DANGER
                });
            }
            if ((sources.data.error.product_name) && (sources.data.error.product_name.length > 0)) {
                Toaster.create({position: Position.TOP}).show({
                    message: sources.data.error.product_name,
                    intent: Intent.DANGER
                });
            }
            if ((sources.data.error.actual_price) && (sources.data.error.actual_price.length > 0)) {
                Toaster.create({position: Position.TOP}).show({
                    message: sources.data.error.actual_price,
                    intent: Intent.DANGER
                });
            }
            if ((sources.data.error.extra_price) && (sources.data.error.extra_price.length > 0)) {
                Toaster.create({position: Position.TOP}).show({
                    message: sources.data.error.extra_price,
                    intent: Intent.DANGER
                });
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="row billUpload py-4 justify-content-center">
                    {
                        this.state.loading ?
                            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-11 col-10 billheading text-left">
                                <div className="col-10 img-full mb-2 mt-2">
                                    <div className="bp3-skeleton" style={{width: "35%", height: "25px"}}>&nbsp;</div>
                                </div>
                                <div className="col-10 img-full mb-2 mt-3">
                                    <div className="bp3-skeleton" style={{width: "100%", height: "20px"}}>&nbsp;</div>
                                </div>

                                <div className="col-12 img-full mb-2 mt-4">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "40px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-6">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "40px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-6 mt-3">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "40px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-6 mt-3">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "40px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-6 mt-3">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "40px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-6 mt-3">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "40px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "40px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-end mt-4">
                                            <div className="bp3-skeleton"
                                                 style={{width: "20%", height: "40px"}}>&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <AddBill uploadBillBrand={this.state.brandBill} uploadBill={this.uploadBill} {...this.props}/>
                    }
                </div>
                {
                    this.state.loading ?
                        <div className="col-12 border-top">
                            <div
                                className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 offset-xl-1 offset-lg-1 offset-md-1 py-4 px-0">
                                <div className="col-10 img-full mb-1 mt-2">
                                    <div className="bp3-skeleton"
                                         style={{width: "30%", height: "30px"}}>&nbsp;</div>
                                </div>
                                <div className="col-12 img-full mt-5">
                                    <div className="row">
                                        <div className="col-2 mb-2">
                                            <div className="bp3-skeleton rounded-circle"
                                                 style={{width: "60%", height: "70px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-2 mt-2">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "25px"}}>&nbsp;</div>
                                            <div className="bp3-skeleton mt-2"
                                                 style={{width: "100%", height: "10px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-2 mt-3">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "25px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-2 mt-3">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "25px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-2 mt-3">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "25px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-2 mt-3">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "25px"}}>&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 offset-xl-1 offset-lg-1 offset-md-1 py-4 px-0">
                                <div className="col-12 img-full mb-2">
                                    <div className="row">
                                        <div className="col-2 mb-2">
                                            <div className="bp3-skeleton rounded-circle"
                                                 style={{width: "60%", height: "70px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-2 mt-2">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "25px"}}>&nbsp;</div>
                                            <div className="bp3-skeleton mt-2"
                                                 style={{width: "100%", height: "10px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-2 mt-3">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "25px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-2 mt-3">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "25px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-2 mt-3">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "25px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-2 mt-3">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "25px"}}>&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 offset-xl-1 offset-lg-1 offset-md-1 py-4 px-0">
                                <div className="col-12 img-full mb-2">
                                    <div className="row">
                                        <div className="col-2 mb-2">
                                            <div className="bp3-skeleton rounded-circle"
                                                 style={{width: "60%", height: "70px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-2 mt-2">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "25px"}}>&nbsp;</div>
                                            <div className="bp3-skeleton mt-2"
                                                 style={{width: "100%", height: "10px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-2 mt-3">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "25px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-2 mt-3">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "25px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-2 mt-3">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "25px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-2 mt-3">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "25px"}}>&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <React.Fragment>
                            <BillHistory
                                userBills={this.state.userBills}
                                getUserActiveBill={() => {
                                    this.setState({
                                        loading: false
                                    }, () => {
                                        this.props.getUserActiveBill(this.state.page, this.state.limit);
                                    })
                                }}
                            />
                        </React.Fragment>
                }
            </React.Fragment>
        );
    }
}