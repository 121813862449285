import React from 'react';
import InfiniteCarousel from 'react-leaf-carousel';
import {Link} from "react-router-dom";


export default class HomeSilder extends React.Component {
    constructor(props){
        super(props);
        this.state={
            brandList:[],
        };
    }

    componentDidMount() {
        if (this.props.brandList !== "" || this.props.brandList != undefined) {
            this.setState({
                brandList: this.props.brandList
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            brandList:nextProps.brandList
        })
    }


    render(){
        return(
            <React.Fragment>
            {
                this.state.brandList.length ?
                    <InfiniteCarousel
                        breakpoints={[
                            {
                                breakpoint: 500,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2,
                                },
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 3,
                                },
                            },
                        ]}
                        dots={false}
                        showSides={true}
                        sidesOpacity={.5}
                        sideSize={.1}
                        slidesToScroll={4}
                        slidesToShow={4}
                        scrollOnDevice={true}
                    >
                        {
                            this.state.brandList.map((affiliate, key) => {
                                return<div className="home-slide col-xl-8 col-md-10 col-sm-10 col-12 p-s-2" key={key}>
                                    <Link to={"/brands/"+affiliate.slug}>
                                        <img alt={affiliate.name} src={affiliate.logo} className="img-fluid" />
                                        <span className="name">{affiliate.name}</span>
                                    </Link>
                                </div>
                            })
                        }


                    </InfiniteCarousel>
                    :
                    ""
            }
            </React.Fragment>

        );
    }
}