import React, {Component} from 'react';
import {Button} from "@blueprintjs/core";
import PinToTOp from "../images/svg/pin_to_top.svg";

export default class ScrollTOTop extends Component {
    render() {
        let prevScrollpos = window.screen.height;
        window.onscroll = function () {
            let currentScrollPos = window.pageYOffset;

            if (prevScrollpos >= (currentScrollPos + prevScrollpos)) {
                document.getElementById("scrollToTop").style.display = "none";
            } else {
                document.getElementById("scrollToTop").style.display = "block";
            }
        };

        return (
            <div className={"fix-to-bottom"} id="scrollToTop">
                <Button
                    className={"fix-button"}
                    minimal={true}
                    onClick={() =>
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        })
                    }
                >
                    <img src={PinToTOp} alt={"pin to top"}/>
                </Button>
            </div>
        );
    }
}

