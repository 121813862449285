import React,{Component} from 'react';
import TopBar from "../core/components/TopBar";
import Brands from './Container/BrandsListContainer';
 

export default class Search extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingData : true,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);
    }



    render(){
        return(
            <div className="container">
                <TopBar title="Best Shopping Website"  cornerText="Bill Upload and Bill History"/>
                <Brands/>
                <div className="page-content-right"></div>
            </div>
        );
    }
}