import {connect} from 'react-redux';
import UserBill from '../Component/UserBill'
import {deleteUserBill, getUserActiveBill, getUserTrashBill} from '../../core/actions/action';


const mapStateToProps = state => {

    return{
        userActiveBillList:state.UserActiveBill.userActiveBillList,
        userTrashBillList:state.UserTrashBill.userTrashBillList,
    }
}

const mapDispatchToProps = dispatch => {

    return {

        getUserActiveBill :(page) => {
            return getUserActiveBill(page);
                /*.then((res)=>{
                    const sources = res.data;

                    if(sources.status===true){
                        dispatch(viewUserActiveBillAction(sources.data.data));
                    }else{
                        dispatch(viewUserActiveBillAction(null));
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    console.log(sources);
                })*/
        },

        getUserTrashBill :(page) => {
            return getUserTrashBill(page);
                /*.then((res)=>{
                    const sources = res.data;

                    if(sources.status===true){
                        dispatch(viewUserTrashBillAction(sources.data.data));
                    }else{
                        dispatch(viewUserTrashBillAction(null));
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    console.log(sources);
                })*/
        },

        deleteUserBill :(billNo, isDelete) => {
            return deleteUserBill(billNo, isDelete);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserBill);
