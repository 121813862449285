import React from 'react'
import { Route } from 'react-router-dom'
import {Header, Footer} from 'gg-react-utilities';
import ScrollTOTop from "./components/ScrollTOTop";

const PrivateRoute = ({ component: Component, ...rest }) => (

    <Route {...rest} render={(props) => (
        <div className="page">
            <Header />
            <Component {...props} />
            <Footer />
            <ScrollTOTop />
        </div>
    )}/>
);

export default PrivateRoute;
