import {connect} from 'react-redux';
import AllBrands from '../Component/brandComponent';
import {getCategoryList, getBrandsList, postUserFavouriteBrand,getBrandsSlider} from '../../core/actions/action';
import {categoryListAction} from '../../core/actions';


const mapStateToProps = state => {

    return{
        brandSlider:state.Search.brandSlider,
        categoryList:state.Search.categoryList,
        brandList:state.Search.brandList,
        postFavourite:state.Search.postFavourite,
    }
}

const mapDispatchToProps = dispatch => {

    return {

        getBrandsSlider :() => {
            return getBrandsSlider();            
        },
    
        getCategoryList :() => {
            return getCategoryList()
                .then((res)=>{
                    const sources = res.data;

                    if(sources.status===true){
                        dispatch(categoryListAction(sources.data));
                    }else{
                        dispatch(categoryListAction(null));
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    console.log(sources);
                })
        },

        getBrandsList :(params) => {

            return getBrandsList(params);
                /*.then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){

                        dispatch(brandListAction(sources.data.data));
                    }else{
                        dispatch(brandListAction(null));
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    console.log(sources);
                })*/
        },

        postUserFavouriteBrand :(affiliate) => {
            return postUserFavouriteBrand(affiliate);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllBrands);
