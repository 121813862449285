
export const categoryListAction = (sources) => {

    return {
        type: 'CATEGORY_LIST',
        sources: sources
    }
}


export const brandListAction = (sources) => {

    return {
        type: 'BRAND_LIST',
        sources: sources
    }
}

export const brandDetailsAction = (sources) => {

    return {
        type: 'BRAND_DETAILS',
        sources: sources
    }
}

export const viewBrandLinkAction = (sources) => {

    return {
        type: 'BRAND_LINK',
        sources: sources
    }
}


export const viewUserActiveBillAction = (sources) => {

    return {
        type: 'USER_ACTIVE_BILLS',
        sources: sources
    }
}

export const viewUserBillAction = (sources) => {

    return {
        type: 'USER_BILLS',
        sources: sources
    }
}


export const deleteUserBillAction = (sources) => {

    return {
        type: 'DELETE_USER_BILLS',
        sources: sources
    }
}


export const viewUserTrashBillAction = (sources) => {

    return {
        type: 'USER_TRASH_BILLS',
        sources: sources
    }
}


export const postUserFavouriteBrandAction = (sources) => {

    return {
        type: 'USER_FAVOURITE_BRAND',
        sources: sources
    }
}

export const brandForBillUploadAction = (sources) => {

    return {
        type: 'BRAND_FOR_UPLOAD_BILL',
        sources: sources
    }
}

export const uploadBillAction = (sources) => {

    return {
        type: 'UPLOAD_USER_BILL',
        sources: sources
    }
}


export const getBrandsSliderAction = (sources) => {

    return {
        type: 'BRAND_SLIDER',
        sources: sources
    }
}
