import React from 'react';
import {Link} from "react-router-dom";
import Config from "../../core/Config";

export default class Offers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            brandSlider: [],
        };
    }

    componentDidMount() {
        this.getOffers()
    }

    getOffers = () => {
        this.props.getBrandsSlider()
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    this.setState( {
                        brandSlider:sources.data,
                    });
                }
            }).catch((error)=>{
            const sources = error.response;
            console.log(sources);
        })
    }



    render() {
        return (
            <React.Fragment>
                {
                    this.state.brandSlider.length ?
                        this.state.brandSlider.map((offersImg, key) => {
                            return (
                                <div className="col-6 img-half left mb-2 pr-s-2" key={key}>
                                    <Link to={"/brands/"+offersImg.slider_link}>
                                        <img
                                            src={offersImg.image_url}
                                            alt={offersImg.slider_title}
                                            title={offersImg.slider_title}
                                            className="img-fluid"
                                            onError={(ev)=>{
                                                ev.target.src = Config.DEFAULT_IMAGE_URL;
                                            }}
                                        />
                                    </Link>
                                </div>
                            )
                        })
                        :
                        ""
                }
            </React.Fragment>
        )
    }
}