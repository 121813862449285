import React from 'react';
import TopBar from "../core/components/TopBar";
import UploadBill from './Container/UserBill'
import {Auth, NoLogin} from "gg-react-utilities";

export default class BillHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingData : true,
            isLoggedIn:false,
        }
    }

    componentDidMount() {
        this.checkAuth();

        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn:res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        },1);
    }

    render(){
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <TopBar title="Bill Upload and Bill History"/>
                        <div className="upvContainer" style={{padding:'200px 0' }}>
                            <NoLogin homeLink={false} imageShow={true}/>
                        </div>
                    </div>
                </div>
            );

        }
        return(
            <div className="container">
                <TopBar title="Bill Upload and Bill History"/>
                <div className="row bill-border mb-5">
                    <div className="col-12">
                        <UploadBill  loading={this.state.loadingData} {...this.props}/>
                    </div>
                    <div className="col-12"></div>
                </div>
                <div className="page-content-right"></div>
            </div>
        );
    }
}