import React, {Component} from 'react';
import { FavoriteRounded, FavoriteBorderRounded } from '@material-ui/icons';
import {Auth} from "gg-react-utilities";

export default class FavoriteIcon extends Component{

    constructor(props){
        super(props);

        this.state={
            show: this.props.show,
            isLoggedIn:false
        }

    }

    async componentDidMount(){

        let isLoggedIn=await Auth.isLoggedIn();

        this.setState({
            isLoggedIn:isLoggedIn
        })

    }

    handleToggleClick=()=>{
        if (this.state.isLoggedIn === true) {
            this.setState({
                show: !this.state.show
            });
            this.props.onFavourit(this.props.affiliateId)
        }else{
            this.props.onFavourit(this.props.affiliateId)
        }
    }

    render(){
        return(
          <React.Fragment>
            {this.state.show ?
              <FavoriteRounded className="favorite float-right heart" onClick={()=>{ this.handleToggleClick() }}/>
              :
              <FavoriteBorderRounded className="float-right heart" onClick={()=>{ this.handleToggleClick() }}/>
            }
          </React.Fragment>
        );

    }

}
