import {connect} from "react-redux";
import {getBrandsDetails, getViewBrandLink} from '../../core/actions/action';
import {brandDetailsAction} from '../../core/actions';
import BrandsDetails from '../Component/BrandsDetails';

const mapStateToProps = state =>{

    return{
        brandDetailsSlug:state.BrandDetails.brandDetailsSlug,
        brandLink:state.BrandDetails.brandLink
    }

}

const mapDispatchToProps = dispatch =>{
    return{
        getBrandsDetails : (slug) =>{
            return getBrandsDetails(slug)
                .then((res) => {
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(brandDetailsAction(sources.data));
                    }else{
                        dispatch(brandDetailsAction(null));
                    }
                }).catch((error) => {
                    const sources = error.response;
                    console.log(sources);
                })
        },


        getViewBrandLink : (slug) =>{
            return getViewBrandLink(slug);
                /*.then((res) => {
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(viewBrandLinkAction(sources.data));
                    }else{
                        dispatch(viewBrandLinkAction(null));
                    }
                }).catch((error) => {
                    const sources = error.response;
                    console.log(sources);
                })*/
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandsDetails)