import React from 'react';
import ViewBill from "../../BillHistory/Container/ViewBill";
import {Button} from "@blueprintjs/core";
import Modal from '../../core/components/Modal';
import BillEmptyImg from "../../core/images/empty-state/billhistory.svg";
import PdfLogo from "../../core/images/pdfLogo.png";

export default class BillList extends React.Component {
    constructor(props){
        super(props);

        this.state= {
            userBills: [],
            bill_no:null,
            isDelete:0,
            popupStatus:"",
            isLoginStatus: false,
        };
    }

    componentDidMount(){
        this.setState({
            userBills:this.props.userTrashBills
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            userBills:nextProps.userTrashBills,
            popupStatus:nextProps.popupStatus
        })

        if(this.state.popupStatus === false){
            this.handleClose();
        }
    }

    renderStatusHtml(status){

        switch (status) {
            case "0":
                return <label className="label text-warning">Pending</label>;
            case "1":
                return <label className="badge text-primary">Verify</label>;
            case "2":
                return <label className="label text-success">Accepted</label>;
            case "3":
                return <label className="label text-danger">Rejected</label>;
            default:
                return <label className="label text-default">Unresolved</label>;
        }
    }

    handleOpen = (data) =>{
        this.setState({
            isOpen: true,
            selectedBillData:data
        });
    }

    handleClose(){
        this.setState({ isOpen: false,selectedBillData:null });
    }


    restoreBill=()=>{
        if (this.state.selectedBillData==null  && !this.state.selectedBillData){

            return false;
        }
        this.props.restoreBill(this.state.selectedBillData.bill_number)
    }

    /*getBills(){
        this.props.NextTrashBillPage()

        this.setState({
            isLoginStatus:true
        });
        this.stopLogin();
    }*/


    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+800);

        if (parseInt(allBookingHeight) === 0){
            if(this.props.stopTrashLoadMore === false){
                this.props.NextTrashBillPage()
            }

            this.setState({
                isLoginStatus:true
            });
            this.stopLogin();
        }
    }

    stopLogin = () => {
        setTimeout(() => {
            this.setState({
                isLoginStatus: false,
            });
        }, 1000);
    }

    renderFileHtml(fileURl){
        let fileExt = fileURl.split('.').pop();
        if(fileExt === "pdf"){
            return  <img src={PdfLogo} alt="" />;
        }else{
            return  <img src={fileURl} alt="" />;
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="table-responsive pt-3" id={"allBooking"} style={{'height':'100%','maxHeight':'800px','overflowY':'scroll'}} onScroll={this.handleScroll}>
                    <table className="bp3-html-table table-borderles">
                        <thead className="thead-light tableBillHeader">
                            <tr>
                                <th>Bill Image</th>
                                <th>Brand</th>
                                <th>Bill Number</th>
                                <th>Date of Purchase</th>
                                <th>Bill Amount</th>
                                <th>Bill Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.userBills.length ?
                                this.state.userBills.map((bill, key) => {
                                    return <tr key={key}>
                                        <td className="hidden-xs hidden-sm hidden-md visible-lg visible-xl">
                                            <div className="border-rounded">
                                                {
                                                    this.renderFileHtml(bill.bill_url)
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mr-1"> {bill.brand_name} <span
                                                className="oh-sub"> {bill.product_name}</span></div>
                                        </td>
                                        <td>
                                            <div className="mr-1">{bill.bill_number}</div>
                                        </td>
                                        <td>
                                            <div className="mr-1">{bill.date_of_purchase}</div>
                                        </td>
                                        <td>
                                            <div className="mr-1 oh-bold">Rs. {bill.bill_amount}</div>
                                        </td>
                                        <td>
                                            <div className="mr-1 bold">
                                                {
                                                    this.renderStatusHtml(bill.is_approove)
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <ViewBill isOpen={this.state.isOpen} billNo={bill.bill_number}/>
                                            <Button
                                                small={true}
                                                intent="success"
                                                icon="refresh"
                                                className="btn-download"
                                                onClick={()=>{
                                                    this.handleOpen(bill)
                                                }}
                                                title="Restore Bill"
                                            />

                                        </td>
                                    </tr>
                                }):
                                this.props.showEmptyTrashStats ?
                                    <tr>
                                        <td colSpan="7" align="center">
                                            <div className="text-center">
                                                <img src={BillEmptyImg} alt=""/>
                                                <p className="mt-3">Oops, we did not found any Bills for your account.</p>
                                            </div>
                                        </td>
                                    </tr>
                                    :
                                    ""
                        }
                        </tbody>
                    </table>
                    {
                        this.state.userBills.length >= 15 ?
                            this.props.stopTrashLoadMore ?
                                ""
                                :
                                <React.Fragment>
                                    <center>
                                        <div className="text-center my-4">
                                            <img src={require('../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                            :
                            ""
                    }
                </div>

                <Modal
                    title={"Restore Bill"}
                    isOpen={this.state.isOpen}
                    footer={
                        <div className="col-12 text-center">
                            <Button onClick={() => this.handleClose()} intent="default" text="Cancel" className="btn "/>
                            <Button intent="primary" text="Yes, restore it!" className="btn ml-2" onClick={()=>{
                                this.restoreBill()
                            }}
                            />
                        </div>
                    }
                >
                    <div className="row mb-5">
                        <div className="col-12 text-center">
                            <strong>Are you sure you want to restore this Bill?</strong>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}