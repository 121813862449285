let initialState = {
    categoryList:[],
    brandList:[],
    postFavourite:null,
    brandSlider:[],
};

const search = (state = initialState, action) => {

    switch (action.type) {

        case 'CATEGORY_LIST':
            return {...state, categoryList: action.sources}
        case 'BRAND_LIST':
            return {...state, brandList: action.sources}
        case 'USER_FAVOURITE_BRAND':
            return {...state, postFavourite: action.sources}
        case 'BRAND_SLIDER':
            return {...state, brandSlider: action.sources}
        default:
            return state
    }
}

export default search;
