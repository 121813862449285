import React from 'react';
import {Link} from "react-router-dom";
import Favorite from './Favorite'
import _ from 'lodash';
export default class BrandsList extends React.Component {
    constructor(props){
        super(props);

        this.state={
            affiliat:[],
            pageNo: props.pageNo,
            perPage:12,
            allowedExecution: true,
            affiliateId:props.affiliateIdData
        };
    }

    componentDidMount(){
        this.getAffiliatesList();
    }
    componentWillReceiveProps(nextProps) {
        if(this.state.allowedExecution){
            this.setState({pageNo: nextProps.pageNo},
                this.getAffiliatesList
            );
        }
    }

    getAffiliatesList(){
        let newAffDataFromApi = this.props.affiliates;
        this.setState({affiliat: newAffDataFromApi });
    }
    render(){
        return(
            <React.Fragment>
                {
                    this.state.affiliat.length ?
                        this.state.affiliat.map((affiliate, key) => {
                            let splitColorCode = _.split(affiliate.color_code, ',');
                            let backgroundColorCode = {backgroundImage: `linear-gradient(135deg, ${splitColorCode[0]} 0%, ${splitColorCode[1]} 100%)`};
                            return<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 my-4" key={key}>
								<div className={" brandsDiv"} key={key} style={backgroundColorCode}>
									<Favorite show={affiliate.fav_status===0?false:true} affiliateId={affiliate.affiliate_id} onFavourit={this.props.onFavourit}/>
									<Link to={"/brands/"+affiliate.slug}>
										<div className="brandsImg">
											<img alt={affiliate.name} src={affiliate.logo} className="img-fluid" />
										</div>
										<h4 className="name">{affiliate.name}</h4>
										<div className="visit-website">
											Visit Website
										</div>
									</Link>
								</div>
                            </div>
                        })
                        :
                        ""
                }
            </React.Fragment>
        );
    }
}
