import React from 'react';
import ViewBill from "../../BillHistory/Container/ViewBill";
import {Button} from "@blueprintjs/core";
import Modal from '../../core/components/Modal';
import { toaster as Toast } from '../../core/components/Toast';


export default class BillList extends React.Component {
    constructor(props){
        super(props);

        this.state= {
            categories: [],
            loading: true,
            page: 1,
            limit: 12,
            userBills: [],
            bill_no:null,
            isDelete:0

        };
    }

    componentDidMount(){
        this.setState( {
            loading: this.props.loading,
            userBills:this.props.userTrashBillList
        },()=>{
            this.props.getUserTrashBill(this.state.page, this.state.limit);
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loading: nextProps.loading,
            userBills:nextProps.userTrashBillList

        })
    }

    renderStatusHtml(status){

        switch (status) {
            case "0":
                return <label className="label text-warning">Pending</label>;
            case "1":
                return <label className="badge text-primary">Verify</label>;
            case "2":
                return <label className="label text-success">Accepted</label>;
            case "3":
                return <label className="label text-danger">Rejected</label>;
            default:
                return <label className="label text-default">Unresolved</label>;
        }
    }

    handleOpen = (data) =>{
        this.setState({
            isOpen: true,
            selectedBillData:data
        });
    }

    handleClose(){
        this.setState({ isOpen: false,selectedBillData:null });
    }

    restoreBill=(bill)=>{
        if (this.state.selectedBillData==null  && !this.state.selectedBillData){

            return false;
        }
        this.props.deleteUserBill(this.state.selectedBillData.bill_number,this.state.isDelete)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    this.handleClose()
                    Toast.success(sources.message);
                    this.props.getUserTrashBill(this.state.page, this.state.limit);
                }
            }).catch((error)=>{
                const sources = error.response;
                Toast.error('error')
                console.log(sources);
        })
    }

    render(){
        return(
            <React.Fragment>
                {
                    this.state.loading ?
                        <div>
                            <div className="col-12 img-full mb-2">
                                <div className="bp3-skeleton" style={{width: "100%", height: "100px"}}>&nbsp;</div>
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="table-responsive">
                                <table className="bp3-html-table table-borderles">
                                    <tbody>
                                    {
                                        this.state.userBills.length ?
                                            this.state.userBills.map((bill, key) => {
                                                return <tr>
                                                    <td>
                                                        <div className="border-rounded"> <img src={bill.bill_url} alt=""/> </div>
                                                    </td>
                                                    <td>
                                                        <div className="mr-1"> {bill.brand_name} <span
                                                            className="oh-sub"> {bill.product_name}</span></div>
                                                    </td>
                                                    <td>
                                                        <div className="mr-1">{bill.bill_number}</div>
                                                    </td>
                                                    <td>
                                                        <div className="mr-1">{bill.date_of_purchase}</div>
                                                    </td>
                                                    <td>
                                                        <div className="mr-1 oh-bold">Rs. {bill.bill_amount}</div>
                                                    </td>
                                                    <td>
                                                        <div className="mr-1">
                                                            {
                                                                this.renderStatusHtml(bill.is_approove)
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ViewBill isOpen={this.state.isOpen} billNo={bill.bill_number}/>
                                                        <Button
                                                            small={true}
                                                            intent="success"
                                                            text="Delete"
                                                            className="btn-download"
                                                            onClick={()=>{
                                                                this.handleOpen(bill)
                                                            }}
                                                        />

                                                    </td>
                                                </tr>
                                            }):
                                            <tr>
                                                <td> No Data Here</td>
                                            </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                }
                <Modal
                    title={"Restore Bill"}
                    isOpen={this.state.isOpen}
                    footer={
                        <div className="col-12 text-center">
                            <Button onClick={() => this.handleClose()} intent="default" text="Cancel" className="btn "/>
                            <Button intent="primary" text="Yes, restore it!" className="btn ml-2" onClick={()=>{
                                this.restoreBill()
                            }}
                            />
                        </div>
                    }
                >
                    <div className="row mb-5">
                        <div className="col-12 text-center">
                            <strong>Are you sure you want to restore this Bill?</strong>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}