import {connect} from 'react-redux';
import Home from '../Component/Home';
import {getBrandsList, getBrandsSlider} from '../../core/actions/action';
import {brandListAction, getBrandsSliderAction} from '../../core/actions';


const mapStateToProps = state => {

    return{
        brandList:state.Search.brandList,
        brandSlider:state.Search.brandSlider
    }
}

const mapDispatchToProps = dispatch => {

    return {

        getBrandsList :(params) => {
            return getBrandsList(params)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(brandListAction(sources.data.data));
                    }else{
                        dispatch(brandListAction(null));
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    console.log(sources);
                })
        },

        getBrandsSlider :() => {
            return getBrandsSlider()
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getBrandsSliderAction(sources.data.data));
                    }else{
                        dispatch(getBrandsSliderAction(null));
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    console.log(sources);
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
