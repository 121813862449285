let initialState = {
    userTrashBillList:[],
};

const usertrashbill = (state = initialState, action) => {

    switch (action.type) {

        case 'USER_TRASH_BILLS':
            return {...state, userTrashBillList: action.sources}
        default:
            return state
    }
}

export default usertrashbill;
