import React from 'react';
import {Button, FormGroup, Position, Toaster, Intent} from "@blueprintjs/core";
import { DateInput} from "@blueprintjs/datetime";
import {Textbox, Select} from 'react-inputs-validation';


export default class AddBill extends React.Component {
    constructor(props) {
        super(props);
        let options = [];
            options.push({id: '', name: 'Select Brand Name'})
            props.uploadBillBrand.map((brand, key) => {
                options.push({id: brand.slug, name: brand.name, key: key})
            });
        
        this.state ={
            validate:false,
            loader:false,
            errors:true,
            brand_name: this.props.match.params.type ? this.props.match.params.type : "",
            brandNameOptions: options,
            product_name:"",
            bill_date:"",
            actual_price:"",
            extra_price:0,
            total:"",
            bill_pic:null,
            platform:"Web",
            isLoginStatus: false,
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.handleFile = this.handleFile.bind(this);
    }

    handleFile(event){
        var infoArea = document.getElementById( 'file-upload-filename' );
        this.setState({
            bill_pic: event.target.files[0]
        });

        infoArea.textContent = event.target.files[0].name;
    }

    toggleValidating(validate) {
        this.setState({ validate });
    }

    onSubmit(event){
        if(this.state.extra_price < 0){
            Toaster.create({position: Position.TOP}).show({message: 'The Extra Price must be at least 0.',intent: Intent.DANGER});
            event.preventDefault();
            this.setState({
                isLoginStatus:false
            });
        }else{
            this.toggleValidating(true);
            if(!this.state.errors){
                const formData = new FormData();
                formData.append('brand_name',this.state.brand_name)
                formData.append('product_name',this.state.product_name)
                formData.append('date_of_purchase',this.state.bill_date)
                formData.append('actual_price',this.state.actual_price)
                formData.append('extra_price',this.state.extra_price)
                formData.append('total',this.state.total)
                formData.append('bill_url',this.state.bill_pic)
                formData.append('platform',this.state.platform)
                this.setState({
                    isLoginStatus:false
                });
                if((this.state.brand_name !== "") && (this.state.actual_price > 0) && (this.state.product_name !== "")) {
                    this.setState({
                        isLoginStatus:true
                    });
                    this.stopLogin();
                    this.props.uploadBill(formData)
                }
            }
            event.preventDefault();
        }
    }

    stopLogin = () => {
        setTimeout(() => {
            this.setState({
                isLoginStatus: false,
            });
        }, 2000);
    }

    formatBillDate(bill_date){
        let formatDate = new Date(bill_date);
        let month = formatDate.getMonth()+1;
        this.setState({bill_date: formatDate.getFullYear()+"-"+ month +"-"+formatDate.getDate()})
    }

    render(){
        return(
            <React.Fragment>
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-11 col-12 billheading text-left">
                    <h2 className="bp3-text-xlarge heading text-left">Upload your Bill here</h2>
                    <p className="text-left"> <span>Note: </span>You have to upload bills only of the brands listed here. Purchases from other brands will be automatically tracked by the system.</p>
                    <form onSubmit={this.onSubmit} className="form-horizontal" encType="multipart/form-data">
                        <div className="row form-data mt-5">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-group">
                                <FormGroup>
                                    <Select
                                        value={this.state.brand_name}
                                        onChange={(brand_name) => {
                                            this.setState({ brand_name });
                                        }}
                                        onBlur={e => {}}
                                        placeholder="Brand Name"
                                        validate={this.state.validate}
                                        validationCallback={res =>
                                            this.setState({
                                                hasTypeError: res, validate: false
                                            })}
                                        optionList={this.state.brandNameOptions}
                                        validationOption={{
                                            name: "Brand",
                                            check: true,
                                            required: true,
                                        }}/>
                                </FormGroup>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-group">
                                <FormGroup>
                                    <Textbox
                                        id={"product_name"}
                                        name="product_name"
                                        type="text"
                                        value={this.state.product_name}
                                        placeholder="Product Name"
                                        onChange={(product_name) => {
                                            this.setState({ product_name });
                                        }}
                                        onBlur={e => {}}
                                        validate={this.state.validate}
                                        validationCallback={res =>
                                            this.setState({ errors:res, validate: false })
                                        }
                                        validationOption={{
                                            name: "Product Name",
                                            check: true,
                                            required: true,
                                        }}/>
                                </FormGroup>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-group">
                                <FormGroup>
                                    <DateInput
                                        formatDate={date => date.toLocaleDateString("en-US")}

                                        id={"bill_date"}
                                        name="bill_date"
                                        type="text"
                                        placeholder="Date of Purchase"
                                        popoverProps={{ position: Position.BOTTOM }}
                                        onBlur={e => {}}
                                        onChange={(bill_date) => this.formatBillDate(bill_date)
                                        }
                                        maxDate={new Date()}
                                        validate={this.state.validate}
                                        validationCallback={res =>
                                            this.setState({ errors:res, validate: false })
                                        }
                                        validationOption={{
                                            name: "Date of Purchase",
                                            check: true,
                                            required: true,
                                        }}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-group">
                                <FormGroup>
                                    <Textbox
                                        id={"actual_price"}
                                        name="actual_price"
                                        type="text"
                                        min="0"
                                        value={this.state.actual_price}
                                        placeholder="Enter your Bill Amount"
                                        onChange={(actual_price) => {
                                            this.setState({ actual_price });
                                        }}
                                        onBlur={e => {}}
                                        validate={this.state.validate}
                                        validationCallback={res =>
                                            this.setState({errors:res, validate: false })
                                        }
                                        validationOption={{
                                            name: "Enter your Bill Amount",
                                            check: true,
                                            required: true,
                                            type: 'number'
                                        }}/>
                                </FormGroup>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-group">
                                <FormGroup>
                                    <Textbox
                                        id={"extra_price"}
                                        name="extra_price"
                                        type="text"
                                        min="0"
                                        value={this.state.extra_price}
                                        placeholder="Enter Extra Charges (Shipping charges, etc.)"
                                        onChange={(extra_price) => {
                                            this.setState({ extra_price });
                                        }}
                                        onBlur={e => {}}
                                        validate={this.state.validate}
                                        validationCallback={res =>
                                            this.setState({errors:res, validate: false })
                                        }
                                        validationOption={{
                                            name: "Enter Extra Charges",
                                            check: true,
                                            required: true,
                                            type: 'number'
                                        }}/>
                                </FormGroup>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-group">
                                <FormGroup>
                                    <Textbox
                                        id={"total"}
                                        name="total"
                                        type="text"
                                        value={Number(this.state.actual_price) + Number(this.state.extra_price)}
                                        placeholder="Total"
                                        disabled={true}
                                        onChange={(total) => {
                                            this.setState( {
                                                total: Number(this.state.actual_price) + Number(this.state.extra_price)
                                            })
                                        }}
                                        onBlur={e => {}}
                                        validate={this.state.validate}
                                        validationCallback={res =>
                                            this.setState({errors:res, validate: false })
                                        }
                                        validationOption={{
                                            name: "Total",
                                            check: true,
                                            required: true,
                                        }}/>
                                </FormGroup>
                            </div>

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-group">
                                <FormGroup>
                                    <label className="bp3-file-input bp3-fill">
                                        <input type="file" name={"bill_pic"} id={"bill_pic"} accept="application/pdf, image/*" onChange={this.handleFile}/>
                                        <span className="bp3-file-upload-input">Upload Bill ( JPG, PNG, JPEG, PDF)</span>
                                    </label>
                                </FormGroup>
                                <div id="file-upload-filename" className="bold"></div>
                            </div>

                            <div className="col-12 text-group mt-s-4 mt-3">
                                <Button intent="primary" type="submit" text="Submit" className="btn btn-primary float-right btn-lg ml-3" loading={this.state.isLoginStatus}/>
                                {/*<Button intent="light" text="Cancel" className="btn btn-light float-right btn-lg"/>*/}
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}