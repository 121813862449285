import React from 'react';
import HomeSlider from "./HomeSlider";
import {Link} from "react-router-dom";
import Offers from "../Container/Offers";
import BSWImage from "../../core/images/BSW.svg";

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingData: true,
            page: 1,
            name: "",
            brandList: [],
            is_popular: 1
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loadingData: true,
            });
        }, 2000);
        this.getBrandList()
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            loadingData: nextProps.loading,
            brandList: nextProps.brandList,
        })
    }

    getBrandList = () => {
        let params = {
            page: this.state.page,
            is_popular: this.state.is_popular
        };
        this.props.getBrandsList(params)
    };

    render() {
        return (
            <React.Fragment>
                <div className="title-box pt-5  pb-0">
                    <div className="col-12">
                        <div
                            className="col-xl-6 col-12 pull-left d-flex justify-content-md-between justify-content-md-around mt-4 mt-s-0">
                            <img src={BSWImage} alt="" className="img-fluid shop-img"/>
                        </div>
                        <div className="col-xl-6 col-12 pull-right pt-4-xl mb-s-4">
                            <span className="bp3-text-xlarge heading mb-3">Best Shopping Websites</span>
                            <Link to="/search" className="sub-heading center">View all</Link>
                        </div>
                    </div>
                </div>
                <div className="popular mt-4 pt-5 pb-2 ">
                    <span className="popular-text">Popular Best Shopping Websites</span>
                    {
                        this.state.loadingData ?
                            <HomeSlider brandList={this.state.brandList}/>
                            :
                            <React.Fragment>
                                <div className="col-12 mt-4">
                                    <div className="row">
                                        <div className="col-xl-3 col-md-3 col-sm-3 col-3 p-s-2 mt-4 mb-5">
                                            <center>
                                                <div className="bp3-skeleton rounded-circle"
                                                     style={{width: "60%", height: "140px"}}>&nbsp;</div>
                                                <div className="bp3-skeleton mt-4"
                                                     style={{width: "50%", height: "30px"}}>&nbsp;</div>
                                            </center>
                                        </div>

                                        <div className="col-xl-3 col-md-3 col-sm-3 col-3 p-s-2 mt-4 mb-5 text-center">
                                            <center>
                                                <div className="bp3-skeleton rounded-circle"
                                                     style={{width: "60%", height: "140px"}}>&nbsp;</div>
                                                <div className="bp3-skeleton mt-4"
                                                     style={{width: "50%", height: "30px"}}>&nbsp;</div>
                                            </center>
                                        </div>

                                        <div className="col-xl-3 col-md-3 col-sm-3 col-3 p-s-2 mt-4 mb-5 text-center">
                                            <center>
                                                <div className="bp3-skeleton rounded-circle"
                                                     style={{width: "60%", height: "140px"}}>&nbsp;</div>
                                                <div className="bp3-skeleton mt-4"
                                                     style={{width: "50%", height: "30px"}}>&nbsp;</div>
                                            </center>
                                        </div>

                                        <div className="col-xl-3 col-md-3 col-sm-3 col-3 p-s-2 mt-4 mb-5 text-center">
                                            <center>
                                                <div className="bp3-skeleton rounded-circle"
                                                     style={{width: "60%", height: "140px"}}>&nbsp;</div>
                                                <div className="bp3-skeleton mt-4"
                                                     style={{width: "50%", height: "30px"}}>&nbsp;</div>
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                    }
                </div>
                <div className="section brand-images">
                    <div className="row">
                        {
                            this.state.loadingData ?
                                <Offers/>
                                :
                                <div className="col-12 img-full mb-2">
                                    <div className="row">
                                        <div className="col-6 img-full mb-2">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "250px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-6 img-full mb-2">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "250px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-6 img-full mb-2">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "250px"}}>&nbsp;</div>
                                        </div>
                                        <div className="col-6 img-full mb-2">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "250px"}}>&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>

            </React.Fragment>
        );
    }
}