import React from 'react';
import {Button, Classes, Dialog} from "@blueprintjs/core";
import PdfLogo from "../../core/images/viewPDF.jpeg";
import {LoopRounded,TagFacesRounded,ThumbDownAltRounded,ThumbUpAltRounded,VerifiedUserRounded} from "@material-ui/icons";

export default class BillHistory extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isOpen:false,
            userBill:""
        };
        this.handleClose = this.handleClose.bind(this)

    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        //this.setState({ userBill: nextProps.viewUserBill });
    }


    handleClose(){
        this.setState({ isOpen: false });
    }

    renderStatusHtml(status){

        switch (status) {
            case "0":
                return <label className="label text-warning">Pending</label>;
            case "1":
                return <label className="badge text-primary">Verify</label>;
            case "2":
                return <label className="label text-success">Accepted</label>;
            case "3":
                return <label className="label text-danger">Rejected</label>;
            default:
                return <label className="label text-default">Unresolved</label>;
        }
    }

    renderUpvStatusHtml(status){

        switch (status) {
            case "0":
                return <label className="label text-warning">Pending</label>;
            case "1":
                return <label className="badge text-success">Send</label>;
            default:
                return <label className="label text-default">Unresolved</label>;
        }
    }

    renderFileHtml(fileURl){
        let fileExt = fileURl.split('.').pop();
        if(fileExt === "pdf"){
            return  <div>
                        <img src={PdfLogo} alt="" className="img-fluid" />
                        <p>
                            <a href={fileURl} target="_blank" rel="noopener noreferrer" className="btn bp3-button bp3-intent-primary">View Uploaded Bill</a>
                        </p>
                    </div>;
        }else{
            return  <img src={fileURl} alt="" className="img-fluid" />;
        }
    }

    renderMilestoneIcon(status){
        // console.log(status);
        switch (status) {
            case 'Processed':
                return <LoopRounded className='bp3-icon'/>
            case 'In Progress':
                return <LoopRounded className='bp3-icon'/>
            case 'Verified':
                return <ThumbUpAltRounded className='bp3-icon'/>
            case 'Verification':
                return <ThumbUpAltRounded className='bp3-icon'/>
            case 'Approved':
                return <VerifiedUserRounded className='bp3-icon'/>
            case 'Adding in UPV':
                return <TagFacesRounded className='bp3-icon'/>
            case 'Deleted':
                return <img src={require('../../core/images/Reject.svg')} alt='cancel' className='bp3-icon'/>
            case 'Rejected':
                return <ThumbDownAltRounded className='bp3-icon'/>
            case 'Approval':
                //return <ThumbDownAltRounded className='bp3-icon'/>
                return <VerifiedUserRounded className='bp3-icon'/>
            default:
                return ''
        }
    }

    render(){
        return(
            <React.Fragment>
                <Button small={true} intent="primary" icon="eye-open" text="" className="btn-view mr-2"
                onClick={()=>{
                    this.setState({isOpen:true})
                    this.props.getUserBillView(this.props.billNo)
                }} title="View Bill"/>
                <Dialog isOpen={this.state.isOpen}
                        onClose={() => this.handleClose()}
                        className="bp3-dialog-large"
                        title="View Bill"
                        canOutsideClickClose={true}
                        canEscapeKeyClose={true}>
                    {this.props.viewUserBill!==null?
                    <div className={Classes.DIALOG_BODY}>
                        <div className="row">
                            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 offset-xl9 offset-lg-1 offset-md-1 offset-sm-1 user-bill text-center">
                                {
                                    this.renderFileHtml(this.props.viewUserBill.bill_url)
                                }
                            </div>
                        </div>
                        <div className="row container viewBillModal">
                            <div className="container slider-one-active col-xl-12 col-12 mt-4">
                                <div className="line">
                                    <div className="dot-move"></div>
                                    {this.props.viewUserBill.milestone.map((res,key)=>{
                                        let status = "dot zero";

                                        if(key===1) status = "dot center";
                                        if(key===2) status = "dot full";
                                        if(key===3) status = "dot end";
                                        return (
                                            <div className={status+' bg-'+res.label.toLowerCase()} key={key}>
                                                {this.renderMilestoneIcon(res.title)}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="steps">
                                    {this.props.viewUserBill.milestone.map((res,key)=>{
                                        return(
                                            <div className={"step step-"+(key+1)} key={key}>
                                                <div className="liner"></div>
                                                <p>{res.title}</p>
                                                <span>{res.message}</span>
                                                <p className="date">{res.date}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="row container mt-5 text-left p-s-0">
                                <div className="row  bill-info">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 mt-4">
                                        <p>Brand Name</p>
                                        <span>{this.props.viewUserBill.brand_name}</span>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 	col-sm-4 col-6 mt-4">
                                        <p>Product Name</p>
                                        <span>{this.props.viewUserBill.product_name}</span>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 mt-4">
                                        <p>Status</p>
                                        <span className="status">
                                            {
                                                this.renderStatusHtml(this.props.viewUserBill.is_approove)
                                            }
                                        </span>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 mt-4">
                                        <p>Date Of Purchase</p>
                                        <span>{this.props.viewUserBill.date_of_purchase}</span>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 mt-4">
                                        <p>Bill Amount</p>
                                        <span className="bold">₹ {this.props.viewUserBill.bill_amount}</span>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 mt-4">
                                        <p>UPV Status</p>
                                        <span className="status">
                                            {
                                                this.renderUpvStatusHtml(this.props.viewUserBill.upv_status)
                                            }
                                        </span>
                                    </div>

                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                                        <p>Comment</p>
                                        <span className="bold">{this.props.viewUserBill.comment}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1"></div>
                            <div className="col-10">
                                <Button onClick={() => this.handleClose()} intent="danger" text="Close" className="btn float-right"/>
                            </div>
                        </div>

                    </div>
                        :null}
                </Dialog>
            </React.Fragment>
        );
    }
}