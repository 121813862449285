import {connect} from 'react-redux';
import Offer from '../Component/Offer';
import {getBrandsSlider} from '../../core/actions/action';
//import {getBrandsSliderAction} from '../../core/actions';


const mapStateToProps = state => {

    return{
        brandSlider:state.Search.brandSlider
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getBrandsSlider :() => {
            return getBrandsSlider();
                /*.then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(getBrandsSliderAction(sources.data.data));
                    }else{
                        dispatch(getBrandsSliderAction(null));
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    console.log(sources);
                })*/
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Offer);
