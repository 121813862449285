import { combineReducers } from 'redux';
//import { persistReducer } from 'redux-persist';
//import storage from 'redux-persist/lib/storage';
import Search from './search';
import BrandDetails from './brandDetails';
import UserActiveBill from './useractivebill';
import UserTrashBill from './usertrashbill';


//import { reducer as formReducer } from 'redux-form'

const recharge = combineReducers({
    Search,
    BrandDetails,
    UserActiveBill,
    UserTrashBill
})

export default recharge;