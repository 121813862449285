import {connect} from 'react-redux';
import BillList from "../Component/BillList";
import {deleteUserBill, getUserTrashBill} from '../../core/actions/action';
import {viewUserTrashBillAction} from '../../core/actions';


const mapStateToProps = state => {

    return{
        userTrashBillList:state.UserTrashBill.userTrashBillList,
    }
}

const mapDispatchToProps = dispatch => {

    return {

        getUserTrashBill :(page, limit) => {
            return getUserTrashBill(page, limit)
                .then((res)=>{
                    const sources = res.data;

                    if(sources.status===true){
                        dispatch(viewUserTrashBillAction(sources.data.data));
                    }else{
                        dispatch(viewUserTrashBillAction(null));
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    console.log(sources);
                })
        },
        deleteUserBill :(billNo, isDelete) => {
            return deleteUserBill(billNo, isDelete);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillList);
