import React from 'react';
import {Button} from "@blueprintjs/core";
import { toaster as Toast } from '../../core/components/Toast';

export default class BillHistory extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isOpen:false,
            userBill:"",
            isDelete:1

        };

    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        //this.setState({ userBill: nextProps.viewUserBill });
    }


    deleteUserBill=()=>{

        this.props.deleteUserBill(this.props.billNo, this.state.isDelete)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    this.props.onDeleteFinish()
                    Toast.success(sources.message);
                }
            }).catch((error)=>{
                const sources = error.response;
                Toast.error(sources.data.message);
                this.props.onDeleteFinish()
        })
    }

    render(){
        return(
            <React.Fragment>
                <Button intent="danger" text="Yes, delete it!" className="btn ml-2" onClick={()=>{
                    this.deleteUserBill()
                }}/>
            </React.Fragment>
        );
    }
}