import React,{Component} from 'react';
import AllBrands from './AllBrands';
import ShowBrands from './ShowBrands';
import {Button, ControlGroup, InputGroup, Toaster, Intent, Position} from "@blueprintjs/core";
//import _ from 'lodash';
import { toaster as Toast } from '../../core/components/Toast';
import { Auth } from 'gg-react-utilities';
import {Link} from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 2
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export default class Brand extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingData : true,
            page:1,
            limit:12,
            name:"",
            brandList:[],
            affiliateId:"",
            Slider:[],
            categoryId:"",
            isUncheck:false,
            stopLoadMore: false,
            showEmptyStats:false,
        }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        this.onSubmit = this.handleSearchClick.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);

        let params={
            page:this.state.page,
        }

        this.props.getCategoryList()
        this.getBrandList(params)
        this.getOffers()
    }

    getOffers = () => {
        this.props.getBrandsSlider()
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    this.setState( {
                        Slider:sources.data,
                    });
                }
            }).catch((error)=>{
            const sources = error.response;
            console.log(sources);
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState( {
            loadingData: nextProps.loading,
        })
    }

    getBrandList=(params)=>{
        this.props.getBrandsList(params)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    let oldAffData = this.state.brandList;
                    let newAffDataFromApi = sources.data.data;
                    let newAffData = oldAffData.concat(newAffDataFromApi);

                    this.setState( {
                        brandList:newAffData,
                        loadingData:false
                    })

                    if(sources.data.data.length === 0){
                        this.setState( {
                            showEmptyStats:true,
                        });
                    }
                    if(sources.data.current_page === sources.data.last_page){
                        this.setState( {
                            stopLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false,
                        })
                    }
                }else{

                }
            }).catch((error)=>{
                const sources = error.response;
                console.log(sources);
            })
    }

    NextPage(){
        let oldPageNo = this.state.page;
        this.setState({
            page: oldPageNo + 1
        },()=>{
            let params={
                page:this.state.page,
            }
            this.getBrandList(params)
        })

    }

    handleSearchClick(event){
        let input = this.state.tempName;
        if(input !== ""){
            if(input.length >= 3){
                this.setState({brandList:[]});
                if(this.state.categoryId !== ""){
                    this.setState({
                        isUncheck:true,
                        brandList:[],
                        name: input,
                        loadingData:true
                    });

                    let params={
                        name:this.state.tempName,
                        category_id:this.state.categoryId,
                    };
                    this.getBrandList(params)
                    event.preventDefault();

                }else{
                    this.setState({
                        isUncheck:true,
                        brandList:[],
                        name: input,
                        loadingData:true
                    });
                    let params={
                        name:this.state.tempName,
                    }
                    this.getBrandList(params)
                    event.preventDefault();
                }

            }
        }else{
            this.setState({brandList:[]});
            if(this.state.categoryId !== ""){
                this.getCategoryAffiliate(this.state.categoryId);
            }else{
                let params={
                    page:1,
                }
                this.getBrandList(params);
            }
        }
        event.preventDefault();
    }

    onSearch=(value)=>{

    }

    onFavourit = (affiliateId) => {
        this.props.postUserFavouriteBrand(affiliateId)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    Toaster.create({position: Position.TOP}).show({message:sources.message ,intent: Intent.SUCCESS});
                }else{
                    Toaster.create({position: Position.TOP}).show({message:sources.message ,intent: Intent.DANGER});
                }
            }).catch((error)=>{
                if(Auth.isLoggedIn() === true){
                    const sources = error.response;
                    Toast.error('error')
                    console.log(sources);
                }
            })
    }

    getCategoryAffiliate = (data) =>{
        if(data !== 'all'){
            this.setState({
                isUncheck:false,
                brandList:[],
                categoryId:data,
                tempName:"",
                loadingData:true
            });
            let params={
                category_id:data
            }
            this.getBrandList(params);
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        }else{
            this.setState({brandList:[], categoryId:'', page:1,loadingData:true});
            let params={
                page:1,
            }
            this.getBrandList(params);
        }
    }

    render(){
        let counter =0
        let slider=this.state.Slider
        //let brandSearch = _.debounce((input)=>{this.handleSearchClick(input)},1000);
        return(
			<div className="col-12 pb-5 brand-search">
				<div className="row">
					<div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12 bill-border category-list">
						{
							this.props.categoryList!==null?
								<AllBrands categoryList={this.props.categoryList} onCategoryAffiliate={this.getCategoryAffiliate} ispUncheck={this.state.isUncheck} />
								:
                                ""
						}
					</div>
					<div className="col-xl-9 col-lg-9 col-md-9 col-sm-8 col-12 show-brands pl-4 p-s-0">
						<div className="row">
                        <div className="col-lg-12 col-12 text-center bsw-side mb-4">
                                                    <Carousel responsive={responsive} showDots={true} arrows={false} autoPlay={true}
                                                        autoPlaySpeed={2000}
                                                        infinite={true}>
                                                        {slider != undefined && slider.map((res) =>
                                                            <div className="mb-4">
                                                                 <Link to={"/brands/"+res.slider_link}>
                                                                    <img className="w-100" src={res.image_url} alt="Deals" />
                                                                </Link>
                                                            </div>
                                                            )
                                                            }
                                                    </Carousel>
                                                </div>
							<div className="search-box col-12 mt-s-4">
                                <form onSubmit={this.onSubmit} className="form-horizontal" encType="multipart/form-data">
                                    <ControlGroup fill={true} vertical={false} className="brand-search">
                                        <InputGroup
                                            placeholder="Search your favourite Best Shopping Website"
                                            className="bs-input-search"
                                            onChange={(e) => {
                                                this.setState({
                                                    tempName: e.target.value
                                                })
                                            }}
                                            /*onChange={(e) => {
                                                this.setState({
                                                    tempName: e.target.value
                                                }, () => {
                                                    brandSearch(this.state.tempName)
                                                })
                                            }}*/
                                            value={this.state.tempName}
                                        />
                                        <Button icon="search" type="submit" className="bs-input-icon"/>
                                    </ControlGroup>
                                </form>
							</div>
						</div>
						<ShowBrands
							brandList={this.state.brandList}
							NextPage={() => this.NextPage()}
							pageNo={this.state.page}
							onSearch={this.onSearch}
							affiliateIdData={this.state.affiliateId}
							onFavourit={this.onFavourit}
                            loading={this.state.loadingData}
                            stopLoadMore={this.state.stopLoadMore}
                            showEmptyStats={this.state.showEmptyStats}
						/>
					</div>
				</div>
				
			</div>
        );
    }
}