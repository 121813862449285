import React from 'react';
import {Button, Classes, Dialog} from "@blueprintjs/core";
import ViewBill from '../Container/ViewBill'
import DeleteBill from '../Container/DeleteBill'
import {Link} from "react-router-dom";
import BillEmptyImg from '../../core/images/empty-state/billhistory.svg';
import PdfLogo from '../../core/images/pdfLogo.png'

export default class BillHistory extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isOpen: false,
            bill_no:"",
            userBills: []
        };

        this.handleClose = this.handleClose.bind(this)
    }

    componentDidMount() {
        this.setState({
            userBills:this.props.userBills
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            userBills:nextProps.userBills
        })
    }

    handleOpen = (data) =>{
        this.setState({ isOpen: true, bill_no:data });
    }

    handleClose(){
        this.setState({ isOpen: false });
    }

    renderStatusHtml(status){

        switch (status) {
            case "0":
                return <label className="label text-warning">Pending</label>;
            case "1":
                return <label className="badge text-primary">Verify</label>;
            case "2":
                return <label className="label text-success">Accepted</label>;
            case "3":
                return <label className="label text-danger">Rejected</label>;
            default:
                return <label className="label text-default">Unresolved</label>;
        }
    }

    renderFileHtml(fileURl){
        let fileExt = fileURl.split('.').pop();
        if(fileExt === "pdf"){
            return  <img src={PdfLogo} alt="" />;
        }else{
            return  <img src={fileURl} alt="" />;
        }
    }

    render(){
        let counter = 0;
        return(
            <React.Fragment>
                <div className="row">
					<div className="col-12 border-top">
						<div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 offset-xl-1 offset-lg-1 offset-md-1 py-4 px-0">
							<h4 className="bp3-text-xlarge heading text-left pt-3 ml-2 my-3">Bill History</h4>
							<div className="pt-3">
								<table className="bp3-html-table table-borderles">
                                    {/*<thead className="hidden-md hidden-sm hidden-xs visible-lg visible-xl">*/}
                                        {/*<tr>*/}
                                            {/*<th>Bill Image</th>*/}
                                            {/*<th>Brand</th>*/}
                                            {/*<th>Bill Number</th>*/}
                                            {/*<th>Date of Purchase</th>*/}
                                            {/*<th>Bill Amount</th>*/}
                                            {/*<th>Bill Status</th>*/}
                                            {/*<th>Action</th>*/}
                                        {/*</tr>*/}
                                    {/*</thead>*/}
									<tbody>
									{
										this.state.userBills.length ?
											this.state.userBills.map((bill, key) => {
                                                counter++;
                                                if(counter < 5)
												return <tr>
													<td>
														<div className="border-rounded">
                                                            {
                                                                this.renderFileHtml(bill.bill_url)
                                                            }
														</div>
													</td>
													<td>
														{bill.brand_name} <span className="oh-sub"> {bill.product_name}</span>
													</td>
													<td>
														{bill.bill_number}
													</td>
													<td className="text-nowrap">
														{bill.date_of_purchase}
													</td>
													<td>
														<div className="oh-bold text-nowrap">Rs. {bill.bill_amount}</div>
													</td>
													<td>
														<div className="bill-status">
															{
																this.renderStatusHtml(bill.is_approove)
															}
														</div>
													</td>
													<td className="text-nowrap">
														<ViewBill isOpen={this.state.isOpen} billNo={bill.bill_number}/>
														<Button small={true} intent="danger" icon="trash" title="Delete Bill" className="btn-download" onClick={() => {this.handleOpen(bill.bill_number)}} disabled={bill.is_approove === 2 ? true : false }/>
													</td>
												</tr>
											}):
											<tr>
                                                <td colSpan="7" align="center">
                                                    <div className="text-center">
                                                        <img src={BillEmptyImg} alt=""/>
                                                        <p className="mt-3">Oops, we did not found <br></br>any Bills for your account.</p>
                                                    </div>
                                                </td>
                                            </tr>
									}
									</tbody>
								</table>
							</div>
						</div>
					</div>
                    {
                        this.state.userBills.length > 4 ?
                            <div className="col-12 pb-3">
                                <Link to="/bills">View all</Link>
                            </div>
                            :
                            ""
                    }
                </div>
				
                <Dialog isOpen={this.state.isOpen}
                        onClose={() => this.handleClose()}
                        title="Delete Bill"
                        canOutsideClickClose={true}
                        canEscapeKeyClose={true}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <div className="row mb-5">
                            <div className="col-12 text-center">
                                <strong>Are you sure you want to delete this Bill? </strong>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center p-s-0">
                                <Button onClick={() => this.handleClose()} intent="default" text="Cancel" className="btn "/>
                                <DeleteBill
                                    billNo={this.state.bill_no}
                                    onDeleteFinish={()=>{
                                        this.handleClose()
                                        this.props.getUserActiveBill()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}