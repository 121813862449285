import React from 'react';
import {Card, RadioGroup, Radio} from "@blueprintjs/core";
import UploadedBills from './UploadedBills';
import TrashBill from "./TrashBill";
import {toaster as Toast} from "../../core/components/Toast";
import {Auth} from "gg-react-utilities";
import Config from '../../core/Config';



export default class UploadBill extends React.Component {
    constructor(props){
        super(props);

        this.state= {
            categories: [],
            loading: true,
            page: 1,
            trashPage:1,
            limit: 12,
            userBills: [],
            userTrashBills: [],
            billType:1,
            isDelete:0,
            popupClose:true,
            stopLoadMore: false,
            stopTrashLoadMore: false,
            showEmptyStats:false,
            showEmptyTrashStats:false,
        };

        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        setTimeout(() => {
            let pageData = 1;
            this.getUserActiveBill(pageData);
            this.getUserTrashBill(pageData);
        }, 1000);

    }

    componentDidMount(){

    }

    userActiveBill = () => {
        this.setState( {
            userBills:[],
            page: 1,
        })
        this.getUserActiveBill(1);
    }

    userTrashBill = () => {
        this.setState( {
            userTrashBills:[],
            trashPage:1,
        })
        this.getUserTrashBill(1);
    }

    getUserActiveBill = (pageData) =>{
        this.props.getUserActiveBill(pageData)
            .then((res)=>{
                const sources = res.data;
                if(sources.status===true){
                    console.log(sources.data.current_page);
                    let oldUploadBillData = this.state.userBills;
                    let newUploadBillDataFromApi = sources.data.data;
                    let newUploadBillData = oldUploadBillData.concat(newUploadBillDataFromApi);
                    this.setState( {
                        userBills:newUploadBillData,
                    })
                    if(sources.data.data.length === 0){
                        this.setState( {
                            showEmptyStats:true,
                        });
                    }
                    if(newUploadBillData.length === sources.data.total){
                        this.setState( {
                            stopLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopLoadMore: false,
                        })
                    }
                }else{

                }
            }).catch((error)=>{
            if(Auth.isLoggedIn() === true){
                const sources = error.response;
                console.log(sources);
            }
        })
    }

    getUserTrashBill = (pageData) =>{
        this.props.getUserTrashBill(pageData)
            .then((res)=>{
                const sources = res.data;

                if(sources.status===true){
                    let oldTrashBillData = this.state.userTrashBills;
                    let newTrashBillDataFromApi = sources.data.data;
                    let newTrashBillData = oldTrashBillData.concat(newTrashBillDataFromApi);
                    this.setState( {
                        userTrashBills:newTrashBillData
                    })
                    if(sources.data.data.length === 0){
                        this.setState( {
                            showEmptyTrashStats:true,
                        });
                    }
                    if(newTrashBillData.length === sources.data.total){
                        this.setState( {
                            stopTrashLoadMore: true,
                        })
                    }else{
                        this.setState( {
                            stopTrashLoadMore: false,
                        })
                    }
                }else{

                }
            }).catch((error)=>{
                const sources = error.response;
                console.log(sources);
            })
    }

    componentWillReceiveProps(nextProps) {
    }

    changeBillType(e){
        this.setState({billType:Number(e.target.value)})
        if(this.state.billType === 1){
            this.userTrashBill();
        }

        if(this.state.billType === 2){
            this.userActiveBill();
        }
    }

    restoreBill=(bill)=>{
        this.props.deleteUserBill(bill,this.state.isDelete)
            .then((res)=>{
                const sources = res.data;
                console.log(sources.message);
                if(sources.status===true){
                    this.setState({ popupClose: false});
                    Toast.success(sources.message);
                    this.userTrashBill();
                }
            }).catch((error)=>{
            const sources = error.response;
            Toast.error(sources.message)
            //console.log(sources);
        })
    }


    NextUploadBillPage(){
        let oldPageNo = this.state.page;
        this.setState({
            page: oldPageNo + 1
        },()=>{
            this.getUserActiveBill(this.state.page)
        })

    }

    NextTrashBillPage(){
        let oldTrashPageNo = this.state.trashPage;
        this.setState({
            trashPage: oldTrashPageNo + 1
        },()=>{
            this.getUserTrashBill(this.state.trashPage)
        })


    }

    render(){
        return(
            <React.Fragment>
                <Card className="p-s-2">
                    <div className="col-12 p-0 bswBill">
                        <div className="bp3-tabs">
                            <ul className="bp3-tab-list bp3-large">
                                <li className="bp3-tab" role="tab" aria-selected="true">Best Shopping Bill History</li>
                                <li className="bp3-tab" role="tab"><a href={Config.STORE_FRONT_URL + 'bill-history'} rel="noopener noreferrer">Store Bill History</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="table-responsive bill-history-list mb-5 mt-4">
                        <RadioGroup
                            className="d-inline option-type float-left ml-3"
                            onChange={(e) => this.changeBillType(e)}
                            name="type"
                            selectedValue={this.state.billType}
                        >
                            <Radio label="Uploaded Bill" value={1} />
                            <Radio label="Trash Bill" value={2} />
                        </RadioGroup>
                        {this.props.userBills===null?
                            <div>
                                <div className="col-12 img-full mb-2">
                                    <div className="bp3-skeleton" style={{width: "100%", height: "100px"}}>&nbsp;</div>
                                </div>
                            </div>
                            :
                            this.state.billType===1 ?
                                <UploadedBills
                                    userBills={this.state.userBills}
                                    NextUploadBillPage={() => this.NextUploadBillPage()}
                                    userActiveBill={() => this.userActiveBill()}
                                    stopLoadMore={this.state.stopLoadMore}
                                    showEmptyStats={this.state.showEmptyStats}
                                />
                                :
                                <TrashBill
                                    userTrashBills={this.state.userTrashBills}
                                    restoreBill={this.restoreBill}
                                    popupStatus={this.state.popupClose}
                                    NextTrashBillPage={() => this.NextTrashBillPage()}
                                    stopTrashLoadMore={this.state.stopTrashLoadMore}
                                    showEmptyTrashStats={this.state.showEmptyTrashStats}
                                />

                        }

                    </div>
                </Card>
            </React.Fragment>
        );
    }
}