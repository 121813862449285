import React from 'react';
import {FormGroup} from '@blueprintjs/core';
import { Radiobox} from 'react-inputs-validation';

export default class AllBrands extends React.Component {
    constructor(props){
        super(props);
        /*let options = [];
            options.push({id: 'all', name: 'All'});
        props.categoryList.map((category, key) => {
            options.push({id: category.category_id, name: category.category_name, key: key})
        });*/

        this.state={
            categories:[],
            categoryId:"",
            categoryNameOptions: [],
            loading: props.loading,
        };
    }

    componentDidMount(){
        if (this.props.isUncheck){
            this.setState({
                categoryId:""
            })
        }

        if(this.state.categoryId === ""){
            this.setState({
                categoryId:"all"
            })
        }

    }

    componentWillReceiveProps(nextProps) {
        let options = [];
        options.push({id: 'all', name: 'All'});
        nextProps.categoryList.map((category, key) => {
            options.push({id: category.category_id, name: category.category_name, key: key})
        });
        this.setState( {
            categoryNameOptions: options
        })

        if (nextProps.isUncheck){
            this.setState({
                loading: nextProps.loading,
                categoryId:""
            })
        }else{
            this.setState( {
                loading: nextProps.loading
            })
        }

    }

    categoryBrands = (categoryId) => {
        this.props.onCategoryAffiliate(categoryId)
    }

    render(){
        return(
            <React.Fragment>
                <div className="filter-search">
                    <h1 className="bp3-text-xlarge heading mt-3">Select Category</h1>
                        {
                            this.props.categoryList.length ?
                                <FormGroup
                                    label="">
                                    <Radiobox
                                        tabIndex="7"
                                        id="type"
                                        name="type"
                                        value={this.state.categoryId}
                                        disabled={false}
                                        optionList={this.state.categoryNameOptions}
                                        onChange={(categoryId) =>{
                                            this.setState({ categoryId });
                                            this.categoryBrands(categoryId)
                                        }}
                                    />
                                </FormGroup>
                                :
                                ""
                        }
                </div>
            </React.Fragment>
        );
    }

}