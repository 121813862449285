import React, {Component} from 'react';
import PrivateRoute from './PrivateRoute';
import { Switch } from 'react-router-dom';
import '../../node_modules/normalize.css/normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";

import HomePage from '../HomePage';
import Search from '../Search';
import Brands from '../Brands';
import BillHistory from '../BillHistory'
import TrashBill from '../TrashBill'
import UserBills from '../UserBills'
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import Config from '../core/Config'
import {Notfound, Maintance} from "gg-react-utilities";


export default class Root extends Component{
    render(){
        return(
            <React.Fragment>
                {Config.IS_MAINTENANCE_MODE ?
                    <Switch>
                        <PrivateRoute path="*" component={Maintance} />
                    </Switch>
                    :
                    <Switch>
                        <PrivateRoute exact path="/" component={Search}/>
                        <PrivateRoute exact path="/search" component={Search}/>
                        <PrivateRoute exact path="/brands/:slug/:key?" component={Brands}/>
                        <PrivateRoute exact path="/bill-history/:type?" component={BillHistory}/>
                        <PrivateRoute exact path="/trash-bill" component={TrashBill}/>
                        <PrivateRoute exact path="/bills" component={UserBills}/>
                        <PrivateRoute path="*"  component={Notfound}/>   
                    </Switch>
                }
            </React.Fragment>
        );
    }
}