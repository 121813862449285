import React from 'react';

const LoadingBrandList = () => (
    <React.Fragment>
        <div className="col-12 d-flex" style={{height: "350px"}}>
            <div className="brandsDiv bp3-skeleton col-4"></div>
            <div className="brandsDiv bp3-skeleton col-4" style={{marginLeft: "48px"}}></div>
            <div className="brandsDiv bp3-skeleton col-4" style={{marginLeft: "50px"}}></div>
        </div>
        <div className="col-12 d-flex mt-5" style={{height: "350px"}}>
            <div className="brandsDiv bp3-skeleton col-4"></div>
            <div className="brandsDiv bp3-skeleton col-4" style={{marginLeft: "48px"}}></div>
            <div className="brandsDiv bp3-skeleton col-4" style={{marginLeft: "50px"}}></div>
        </div>
    </React.Fragment>
);

export default LoadingBrandList;