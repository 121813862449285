import React from 'react';
import TopBar from "../core/components/TopBar";
import BillList from "./Container/UserTrashBill";

export default class TrashBill extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingData : true,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);
    }

    render(){
        return(
            <div className="container">
                <TopBar title="Trash Bill History"/>
                <div className="bill-border mb-5">
                    <div className="col-12">
                        <BillList  loading={this.state.loadingData}/>
                    </div>
                    <div className="col-12"></div>
                </div>
                <div className="page-content-right">                    
                </div>
            </div>
        );
    }
}