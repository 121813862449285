import React from 'react';
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import {Link} from "react-router-dom";
import { withRouter } from 'react-router-dom';

class TopBar extends React.Component {
    /*constructor(props) {
        super(props);
    };*/


    render(){
        return(
            <div className="top-bar d-xl-flex d-lg-flex d-md-flex d-sm-flex">
                <div className="col-xl-6 col-md-6 col-sm-7 col-12 text-left p-s-0 p-sm-0">
                    {
                        this.props.isDashboard ?
                            <span>
                                <span className="back-icon d-none">
                                    <KeyboardArrowLeftRounded />
                                </span>
                                <h1 className="titles m-0">{this.props.title}</h1>
                            </span>
                            :
                            <span>
                                <span className="back-icon" onClick={() => this.props.history.goBack()}>
                                    <KeyboardArrowLeftRounded />
                                </span>
                                <h1 className="title my-0 ml-3">{this.props.title}</h1>
                            </span>
                    }

				</div>
                {
                   this.props.cornerText ?
                    <div className="col-sm-5 col-md-6 col-xl-6 col-12 text-right pr-xl-0 pr-lg-0 pr-md-0 p-s-0 p-sm-0">
                        <small className="title-right"><Link to="/bill-history">{this.props.cornerText}</Link></small>
                    </div>
                   :''
                }
            </div>
        );
    }
}

export default withRouter(TopBar);