import React, {Component} from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import configureStore from './core/store/configureStore';
import { BrowserRouter as Router } from 'react-router-dom';
import Root from './core';
import {GgUtils} from "gg-react-utilities";

let { store, persistor } = configureStore();

export default class App extends Component{

    constructor(props){
        super(props);
        this.state={
            GOOGLE_ANALYTICAL_SERVER:['production']
        }
    }

    componentDidMount() {
        if(process.env.REACT_APP_ENV==='production'){
            GgUtils.sentryScripts(process.env.REACT_APP_SENTRY_CODE);
            GgUtils.googleAnalyticalScripts(process.env.REACT_APP_GOOGLE_AUTHENTICATE_CODE);
        }
    }

    render(){
        return(
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router>
                        <Root />
                    </Router>
                </PersistGate>
            </Provider>
        )

    }

}