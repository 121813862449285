import React from 'react';
import TopBar from "../core/components/TopBar";
import Home from "./Container/PopularBrandsContainer";

export default class HomePage extends React.Component {
    render() {
        return (
            <div className="container">
                <TopBar title="Best Shopping Websites" cornerText="Bill Upload and Bill History" isDashboard={true}/>
                <Home/>
                <div className="page-content-right">
                </div>
            </div>
        );
    }
}