import React from 'react';
import BrandsDetails from './Container/BrandsDetails'

export default class Brands extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 2000);
    }

    render() {
        return (
            <div className="container">
                {
                    this.state.loadingData ?
                        <div>
                            <div className="col-12 img-full mb-2">
                                <div className="bp3-skeleton" style={{width: "100%", height: "500px"}}>&nbsp;</div>
                            </div>
                        </div>
                        :
                        <BrandsDetails {...this.props} />
                }
                <div className="page-content-right">
                </div>
            </div>
        );
    }
}