import React from 'react';
import {Button, Classes, Dialog} from "@blueprintjs/core";
import ViewBill from '../../BillHistory/Container/ViewBill'
import DeleteBill from '../../BillHistory/Container/DeleteBill'
import BillEmptyImg from "../../core/images/empty-state/billhistory.svg";
import PdfLogo from "../../core/images/pdfLogo.png";

export default class BillHistory extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isOpen: false,
            bill_no:"",
            userBills: [],
            isLoginStatus: false,
        };
        this.handlepopupClose = this.handlepopupClose.bind(this)
    }

    componentDidMount() {
        this.setState({
            userBills:this.props.userBills
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            userBills:nextProps.userBills
        })
    }

    handleOpen = (data) =>{
        this.setState({ isOpen: true, bill_no:data });

    }

    handleClose(){
        this.setState({ isOpen: false });
        this.props.userActiveBill();
    }

    handlepopupClose(){
        this.setState({ isOpen: false });
    }

    renderStatusHtml(status){

        switch (status) {
            case "0":
                return <label className="label text-warning">Pending</label>;
            case "1":
                return <label className="badge text-primary">Verify</label>;
            case "2":
                return <label className="label text-success">Accepted</label>;
            case "3":
                return <label className="label text-danger">Rejected</label>;
            default:
                return <label className="label text-default">Unresolved</label>;
        }
    }

    /*getBills(){
        this.props.NextUploadBillPage()

        this.setState({
            isLoginStatus:true
        });
        this.stopLogin();
    }*/

    handleScroll=()=>{

        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight)-(allBooking.scrollTop+800);

        if (parseInt(allBookingHeight) === 0){
            if(this.props.stopLoadMore === false){
                this.props.NextUploadBillPage()
            }

            this.setState({
                isLoginStatus:true
            });
            this.stopLogin();
        }
    }

    stopLogin = () => {
        setTimeout(() => {
            this.setState({
                isLoginStatus: false,
            });
        }, 1000);
    }

    renderFileHtml(fileURl){
        let fileExt = fileURl.split('.').pop();
        if(fileExt === "pdf"){
            return  <img src={PdfLogo} alt="" />;
        }else{
            return  <img src={fileURl} alt="" />;
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="table-responsive pt-3" id={"allBooking"} style={{'height':'100%','maxHeight':'800px','overflowY':'scroll'}} onScroll={this.handleScroll}>
                    <table className="bp3-html-table table-borderles">
                        <thead className="thead-light tableBillHeader">
                            <tr>
                                <th>Bill Image</th>
                                <th>Brand</th>
                                <th>Bill Number</th>
                                <th>Date of Purchase</th>
                                <th>Bill Amount</th>
                                <th>Bill Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.userBills.length ?
                                    this.state.userBills.map((bill, key) => {
                                        return <tr key={key}>
                                            <td className="hidden-xs hidden-sm hidden-md visible-lg visible-xl">
                                                <div className="border-rounded">
                                                    {
                                                        this.renderFileHtml(bill.bill_url)
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                {bill.brand_name} <span className="oh-sub"> {bill.product_name}</span>
                                            </td>
                                            <td>
                                                {bill.bill_number}
                                            </td>
                                            <td>
                                                {bill.date_of_purchase}
                                            </td>
                                            <td>
                                                <div className="oh-bold">Rs. {bill.bill_amount}</div>
                                            </td>
                                            <td>
                                                <div className="bill-status">
                                                    {
                                                        this.renderStatusHtml(bill.is_approove)
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <ViewBill isOpen={this.state.isOpen} billNo={bill.bill_number}/>
                                                <Button small={true} intent="danger" icon="trash" className="btn-download"
                                                        onClick={() => {this.handleOpen(bill.bill_number)}}
                                                        disabled={bill.is_approove ===2 ? true : false }
                                                        title="Delete Bill"
                                                />
                                            </td>
                                        </tr>
                                    }):
                                    this.props.showEmptyStats ?
                                        <tr>
                                            <td colSpan="7" align="center">
                                                <div className="text-center">
                                                    <img src={BillEmptyImg} alt=""/>
                                                    <p className="mt-3">Oops, we did not found any Bills for your account.</p>
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        ""
                            }
                        </tbody>
                    </table>
                    {
                        this.state.userBills.length >= 15 ?
                            this.props.stopLoadMore ?
                                ""
                                :
                                <React.Fragment>
                                    <center>
                                        <div className="text-center my-4">
                                            <img src={require('../../core/images/loader.gif')} alt={""} style={{width:'50px',height:'50px'}}/>
                                        </div>
                                    </center>
                                </React.Fragment>
                            :
                            ""
                    }
                </div>

                <Dialog isOpen={this.state.isOpen}
                        onClose={() => this.handlepopupClose()}
                        title="Delete Bill"
                        canOutsideClickClose={true}
                        canEscapeKeyClose={true}>
                    <div className={Classes.DIALOG_BODY}>
                        <div className="row mb-5">
                            <div className="col-12 text-center">
                                <strong>Are you sure you want to delete this Bill? </strong>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center p-s-0">
                                <Button onClick={() => this.handlepopupClose()} intent="default" text="Cancel" className="btn "/>
                                <DeleteBill
                                    billNo={this.state.bill_no}
                                    onDeleteFinish={()=>{
                                        this.handleClose()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}